import React from 'react'

export default function Footer() {
  return (
    <div className="footer">
      <footer>
        <p>© 2013-{new Date().getFullYear()} Shekib Aziz. All Rights Reserved.</p>
      </footer>
    </div>
  )
}
