import React, { lazy, Suspense } from 'react'
import { motion } from 'framer-motion'

import LagmarksPic from '../../assets/projects/lagmarks/flow'
import radiumPic from '../../assets/img/radiumCodePic.svg'
import yelpPic from '../../assets/img/yelpClone.svg'
import sheekMediaPic from '../../assets/img/sheekMediaPic.svg'
import schoolOfOptics from '../../assets/img/schooOfOpticsPic.svg'
import pic2048 from '../../assets/projects/2048.png'
// import lagmarksPic from '../../assets/img/lagmarks.png'
import chessVideo from '../../assets/projects/chess1.mp4'
import topWave from '../../assets/img/topWaveProjects.svg'
import bottomWave from '../../assets/img/bottomWaveProjects.svg'

const Project = lazy(() => import('./ProjectCard'))
const projects = [
  {
    imgComponent: LagmarksPic,
    imgAlt: 'Lagmarks project Shekib Aziz',
    cardTitle: 'Lagmarks',
    cardParagraph: `Lagmarks helps people get in control of their finances. Lagmarks offers chart that will you understand your finances and help you categories your expenses.`,
    tech: 'HTML, CSS, JavaScript, React, Node, MongoDB, Express, mongoose, Pug, Nodemailer, Jsonwebtoken, Mobx, Charts.js, Final-form, React-beautiful-dnd, Styled-components, React testing library,',
    linkTo: 'http://lagmarks.com',
  },
  {
    video: chessVideo,
    imgAlt: 'Chess project Shekib Aziz',
    cardTitle: 'Rapid Rated Chess',
    cardParagraph: `Chess is an abstract strategy game and involves no hidden information. It is played on a chessboard with 64 squares arranged in an eight-by-eight grid. At the start, each player controls sixteen pieces: one king, one queen, two rooks, two bishops, two knights, and eight pawns.`,
    tech: 'HTML, CSS, JavaScript, React, Node, MongoDB, Express, mongoose, Nodemailer, React context api, React-beautiful-dnd, Styled-components, React testing library, Socket.io',
    linkTo: 'http://afghanchess.com',
  },
  {
    imgSrc: radiumPic,
    imgAlt: 'Radium Code project Shekib Aziz',
    cardTitle: 'Radium Code',
    cardParagraph: `Radium Code is looking to innovate the way that people learn web programming. Radium Code offers a powerful educational experience that will greatly affect the way that people learn through a streamlined and user-focused engineering.`,
    tech: 'HTML, CSS, Bootstrap 4, JavaScript, Jquery, Node, Express, Nginx, AWS, Pug/Jade, MongoDB, PM2, Custom Analytics',
    linkTo: 'https://radiumcode.com',
  },
  {
    imgSrc: sheekMediaPic,
    imgAlt: 'Sheek Media project by Shekib Aziz',
    cardTitle: 'Sheek Media',
    cardParagraph: `Sheek Media is a development company centered around building contemporary websites, web applications, and software focused on benefiting and growing businesses through sleek and modern design.`,
    tech: 'Technologies may vary based on costumers needs',
    linkTo: 'https://sheekmedia.com',
  },
  {
    imgSrc: yelpPic,
    imgAlt: 'yelp clone project by Shekib Aziz',
    cardTitle: 'Yelp Clone',
    cardParagraph: ` Yelp clone is a project I made to practice my knowledge of React and how components communicate to one another. In this project I learned how to update state and manipulate the state of the parent component through its children. Also how different life-cycle methods work. `,
    tech: 'HTML, CSS, JavaScript, React,  Yelp Api',
    linkTo: 'https://placefinder.netlify.com',
  },
  {
    imgSrc: schoolOfOptics,
    imgAlt: 'school of optics project by Shekib Aziz',
    cardTitle: 'School of Optics',
    cardParagraph: `School of optics is a interactive learning  platform that helps opticians pass their biyearly exam to keep their status of an optician active. With our interactive mix-match, quizzes, fill in the blank and many more tools will help you to keep your status active in no time. `,
    tech: 'HTML, CSS, Custom Design, JavaScript, AWS, Node, Express, Nginx, React, MongoDB, PM2',
    linkTo: 'https://schoolofoptics.com',
  },
  {
    imgSrc: pic2048,
    imgAlt: '2048 replica project by Shekib Aziz',
    cardTitle: 'Famous 2048 game',
    cardParagraph: `I played this game when it just came out and wanted to make it for fun :)`,
    tech: 'React',
    linkTo: 'https://2048-test.netlify.app/',
  },
]

export const appear = {
  initial: { opacity: 0, scale: 0.5 },
  whileInView: { opacity: 1, scale: 1 },
  transition: { duration: 0.5 },
}

export default function Projects({ projectsRef }) {
  return (
    <>
      <img className='blobProject' src={topWave} alt='projects shekib aziz' />
      <div ref={projectsRef} className='projectsSection'>
        <motion.h3 {...appear}>Projects</motion.h3>
        <div className='projects'>
          {projects.map((project, i) => (
            <Suspense key={project.cardTitle} fallback={<div>Loading...</div>}>
              <Project
                i={i}
                key={project.cardTitle}
                video={project.video}
                imgComponent={project.imgComponent}
                imgSrc={project.imgSrc}
                imgAlt={project.imgAlt}
                cardTitle={project.cardTitle}
                cardParagraph={project.cardParagraph}
                tech={project.tech}
                linkTo={project.linkTo}
              />
            </Suspense>
          ))}
        </div>
      </div>
      <img
        className='blobProject'
        src={bottomWave}
        alt='projects shekib aziz'
      />
    </>
  )
}
