import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import landingPhotoL from '../assets/img/desktop.svg'
import landingPhotoM from '../assets/img/desktop-medium.svg'
import landingPhotoS from '../assets/img/desktop-sm.svg'

export default function LandingPage({
  talksRef,
  aboutRef,
  projectsRef,
  contactRef,
}) {
  const scrollToMyRef = (ref) => {
    let scrollTo = ref.current.offsetTop
    for (let i = 1; i <= scrollTo - 50; i++) {
      setTimeout(() => window.scrollTo(i, i++), i * 0.2)
    }
  }
  const landingPicAnimate = {
    initial: { opacity: 0, x: 200 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.5 },
  }
  return (
    <div className='background-picture'>
      <AnimationArea></AnimationArea>
      <div className='landingDiv  mw-90p mt-30px'>
        <nav>
          <div rel='noopener noreferrer' className='logo'>
            Shekib
          </div>
          <div className='rightSizeNavLinks'>
            <div
              onClick={() => scrollToMyRef(aboutRef)}
              rel='noopener noreferrer'
            >
              About
            </div>
            <div
              onClick={() => scrollToMyRef(talksRef)}
              rel='noopener noreferrer'
            >
              Talks
            </div>
            <div
              onClick={() => scrollToMyRef(projectsRef)}
              rel='noopener noreferrer'
            >
              Projects
            </div>
            <div
              onClick={() => scrollToMyRef(contactRef)}
              rel='noopener noreferrer'
            >
              Contact
            </div>
          </div>
        </nav>
        <div className='landing-content'>
          <div>
            <motion.img
              {...landingPicAnimate}
              className='landingPhoto-l'
              src={landingPhotoL}
              alt='Shekib Aziz'
            />
            <motion.img
              {...landingPicAnimate}
              className='landingPhoto-m'
              src={landingPhotoM}
              alt='Shekib Aziz'
            />
            <motion.img
              {...landingPicAnimate}
              className='landingPhoto-s'
              src={landingPhotoS}
              alt='Shekib Aziz'
            />

            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className='h1'>Staff Software Engineer</h1>
              <p>
                Software engineering is more than <br />
                just knowing how to code.
              </p>
              <button
                onClick={() => scrollToMyRef(projectsRef)}
                className='project-button'
              >
                Projects
              </button>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const AnimationArea = () => (
  <Area>
    <ul className='box-area'>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </Area>
)

const Area = styled.div`
  /* background: #fdc830; */
  /* background: -webkit-linear-gradient(to right, #f37335, #fdc830); */
  /* background: linear-gradient(to right, #f37335, #fdc830); */
  /* width: 100%; */
  /* height: 100vh; */

  .box-area {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .box-area li {
    position: absolute;
    display: block;
    list-style: none;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 20s linear infinite;
    bottom: -150px;
  }
  .box-area li:nth-child(1) {
    left: 86%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .box-area li:nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    animation-delay: 1.5s;
    animation-duration: 10s;
  }
  .box-area li:nth-child(3) {
    left: 70%;
    width: 100px;
    height: 100px;
    animation-delay: 5.5s;
  }
  .box-area li:nth-child(4) {
    left: 42%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 15s;
  }
  .box-area li:nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
  }
  .box-area li:nth-child(6) {
    left: 15%;
    width: 110px;
    height: 110px;
    animation-delay: 3.5s;
  }
  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }
`
