import React from 'react'
import Landing from './Landing'
import About from './About'
import Talks from './Talks'
import Contact from './Contact'
import Footer from './Footer'
import Projects from './ProjectCard/Projects'

import './Home.css'

export default function Home() {
  const aboutRef = React.useRef()
  const talksRef = React.useRef()
  const contactRef = React.useRef()
  const projectsRef = React.useRef()

  return (
    <div className='entirePage'>
      <Landing
        aboutRef={aboutRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
        talksRef={talksRef}
      />
      <About aboutRef={aboutRef} />
      <Talks talksRef={talksRef} />
      <Projects projectsRef={projectsRef} />
      <Contact contactRef={contactRef} />
      <Footer />
    </div>
  )
}
