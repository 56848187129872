import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import shekib from '../assets/img/profile.jpg'
//social media Icons
// import fb from '../assets/img/facebook.svg'
// import ig from '../assets/img/instagram.svg'
// import youtube from '../assets/img/youtube.svg'
import linkedIn from '../assets/img/linkedin.svg'
import git from '../assets/img/gitlab.svg'

import resume from './resume.pdf'

const aboutAnimate = {
  initial: { opacity: 0, scale: 0.7 },
  whileInView: { opacity: 1, scale: 1 },
  transition: { duration: 0.5 },
  viewport: { once: true },
}
const rotate = {
  transition: { duration: 2, repeat: Infinity },
  animate: { rotate: 360 },
}
export default function About({ aboutRef }) {
  return (
    <motion.div
      {...aboutAnimate}
      ref={aboutRef}
      className='about mw-90p mt-100px'
    >
      <h2 className='name'>Shekib Aziz</h2>
      <div className='aboutMe'>
        <div className='col1'>
          <ProfilePic src={shekib} alt='shekib aziz' />
          <a
            className='project-button'
            target='_blank'
            rel='noopener noreferrer'
            href={resume}
            style={{
              background: 'rgb(0, 105, 255)',
              color: 'rgb(255, 255, 255)',
              boxShadow: 'white 1px 1px 18px 3px',
              textDecoration: 'none',
              fontWeight: 700,
            }}
          >
            RESUME
          </a>
        </div>

        <div className='col2'>
          <ul>
            <h3>About Me</h3>
            <li>B.S. Computer Science</li>
            <li>Languages: English, Russian, Dari </li>
            <li>Interest in Marketing & Economy</li>
            <li>3d printing & robotics</li>
            <li>Marathon / ultramarathoner</li>
            <li className='social'>
              <a
                href='https://gitlab.com/ShekibAziz'
                target='_blank'
                rel='noopener noreferrer'
              >
                <motion.img
                  {...rotate}
                  src={git}
                  style={{ height: 30 }}
                  alt='shekib aziz git'
                />
              </a>
              <a
                href='https://www.linkedin.com/in/shekibaziz'
                target='_blank'
                rel='noopener noreferrer'
              >
                <motion.img
                  {...rotate}
                  src={linkedIn}
                  style={{ height: 30 }}
                  alt='shekib aziz linkedin'
                />
              </a>
              {/* <a
										href="https://www.youtube.com/channel/UCqQ81OUiEC96PlX1jlaByWA"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={youtube} style={{ height: 30 }} alt="shekib aziz youtube channel" />
									</a>
									<a
										href="https://www.facebook.com/shekib.aziz.9"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={fb} style={{ height: 30 }} alt="shekib aziz facebook" />
									</a>
									<a
										href="https://www.instagram.com/shekibaziz"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={ig} style={{ height: 30 }} alt="shekib aziz instagram" />
									</a> */}
            </li>
          </ul>
        </div>
        <hr className='hr' />
        <div className='skills'>
          <div>
            <h3 className='h3'> Technologies & Software</h3>
            <ul>
              <br />
              <li>
                Javascript ( ES6, ES7, ES8+ ), React, React Context Api, React
                Native, Jest, Final-form, React-beautiful-dnd, React Testing
                Library, Redux, Mobx State Tree, Styled-components, Socket.io
                client, JQuery, HTML5/CSS3, Git, Bootstrap, Material UI,
                Ajax/Fetch/Axios Charts.js, D3.js, SEO.
              </li>
              <br />
              <li>
                Node, Express, AWS, Pug/Jade, MySQL, MongoDB, Mongoose, Java,
                Rest Apis, Postman, Linux, Bash, Heroku, MERN, Nodemailer,
                Jsonwebtoken, Socket.io.
              </li>
              <br />
              <li>
                Agile, Scrum, Trello, Jira, Adobe Design, Figma, Framer Motion,
                HotJar, Google Analytics, Facebook Ads, Google Search Console.
              </li>
            </ul>
          </div>
          {/* <div>
            <h3 className="h3">Other Skills</h3>
            <p>Adobe Design Experience, Final Cut Pro, Youtube, Video Editing, Public Speaking, Leadership.</p>
          </div> */}
        </div>
      </div>
    </motion.div>
  )
}

const ProfilePic = styled.div`
  margin-bottom: 50px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  background-image: url(${shekib});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  /* border: 5px solid #4a78ff; */
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: #4a78ff;
    z-index: -1;
    animation: bo 1.3s infinite ease;
  }
  &::after {
    z-index: -2;
    animation-delay: 1s;
    // background: #1363DF;
  }

  @keyframes bo {
    to {
      transform: scale(3);
      opacity: 0;
    }
  }
`
