/* eslint-disable */
import React from 'react'
import styled from 'styled-components'

export default function FlowChart({ className }) {
  React.useEffect(() => {
    // list all keyframes here
    var key1 = document
      .querySelector('.morph-key--1')
      .getAttribute('d')
      .match(/[a-zA-Z]+|[+-]?[0-9.]+/g)
    var key2 = document
      .querySelector('.morph-key--2')
      .getAttribute('d')
      .match(/[a-zA-Z]+|[+-]?[0-9.]+/g)
    var key3 = document
      .querySelector('.morph-key--3')
      .getAttribute('d')
      .match(/[a-zA-Z]+|[+-]?[0-9.]+/g)

    var playByTime = 'true'
    var loopTimeline = 'true'

    // easing:
    var linear = 1

    //define for each keyframe: from, to, time, easing
    var timeline = [
      [key1, key1, 1, linear],
      [key1, key2, 2, linear],
      [key2, key2, 1, linear],
      [key2, key3, 2, linear],
      [key3, key3, 1, linear],
      [key3, key1, 2, linear],
    ]

    var timelineLength = timeline.length

    var saveTimeline = []

    for (var i = 0; i < timelineLength; i++) {
      saveTimeline.push([]) //creates an array to later push frames into
      renderAnimation(timeline[i], i)
    }

    var time = 1 //in seconds with 30 fps
    var start = ''
    var end = ''
    var easing
    var currentFrame

    function renderAnimation(parameter, timelineNumber) {
      start = parameter[0]
      end = parameter[1]
      time = parameter[2]
      easing = parameter[3]

      calculator(timelineNumber)
    }

    //CALCULATOR CALCULATOR CALCULATOR CALCULATOR CALCULATOR CALCULATOR CALCULATOR CALCULATOR CALCULATOR
    function calculator(timelineNumber) {
      var letter = ''

      var newPath = [] //passed into applyChange as d

      function applyChange(d) {
        saveTimeline[i].push(d)
      }

      function calculateDifference(p1, p2, i) {
        if (isNaN(p1)) {
          //is not a number
          if (p1 === 'z' || p1 === 'Z') {
            // z Z won't be saved as letter but passed directly
            return p1
          } else {
            //saving the current letter to be added to next number
            letter = p1
            return 'skip'
          }
        } else {
          if (p2 > p1) {
            var addThis = ((p2 - p1) / (30 * time)) * currentFrame
            var addLetter = letter
            letter = ''
            return addLetter + '' + (Number(addThis) + Number(p1))
          } else {
            var subtractThis = ((p1 - p2) / (30 * time)) * currentFrame //if p1 = p2 -> this line = 0
            var addLetter = letter
            letter = ''
            return addLetter + '' + (Number(p1) - Number(subtractThis))
          }
        }
      }

      function loop() {
        for (var i = 0; i < start.length; i++) {
          var newPoint = calculateDifference(start[i], end[i], i)
          if (newPoint != 'skip') {
            newPath.push(newPoint)
          }
          if (i == start.length - 1) {
            applyChange(newPath)
            currentFrame = currentFrame + 1
            newPath = []
          }
        }
      }

      var frames = time * 30

      for (var counter = 0; counter < frames; counter++) {
        currentFrame = counter
        loop()
      }
    }

    //PLAYBACK PLAYBACK PLAYBACK PLAYBACK PLAYBACK PLAYBACK PLAYBACK PLAYBACK PLAYBACK
    var pf = 0
    var keyCount = 0

    if (playByTime == 'true') {
      var play = setInterval(function () {
        document.querySelector('.morph-key--1').setAttribute('d', saveTimeline[keyCount][pf].join(' '))
        pf++
        if (pf == saveTimeline[keyCount].length) {
          if (keyCount >= timelineLength - 1) {
            if (loopTimeline == 'true') {
              pf = 0
              keyCount = 0
            } else {
              clearInterval(play)
            }
          } else {
            pf = 0
            keyCount++
          }
        }
      }, 1000 / 30) // 30 times per second -> 30fps
    }
    return () => clearInterval(play)
  }, [])
  return (
    <div className={className}>
      <FlowChartAll>
        <div className="layout">
          <div className="card">
            <svg
              className="graph"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="597.28px"
              height="370.501px"
              viewBox="0 0 597.28 370.501"
              enableBackground="new 0 0 597.28 370.501"
              xmlSpace="preserve"
            >
              <g id="curve--1">
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="298.6401"
                  y1="370.501"
                  x2="298.6401"
                  y2="14.5015"
                >
                  <stop offset={0} style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
                  <stop offset="0.1413" style={{ stopColor: '#FFFFFF', stopOpacity: '0.0283' }} />
                  <stop offset={1} style={{ stopColor: '#FFFFFF', stopOpacity: '0.2' }} />
                </linearGradient>
                <path
                  fill="url(#SVGID_1_)"
                  d="M596.28,370.501c0,0,0-114,0-155c-41.28-17-54.28-40-85.04-87
		c-11.642-17.788-63.78-113.99-85.04-114c-27.2-0.013-35.2,98.994-85.04,100.994c-24.033,0.965-61.136,89.312-85.04,86.646
		c-21.856-2.437-62.521-45.872-85.04-38.64c-38.293,12.297-34.08,92-85.04,88c-29.982-2.354-49.54,53.49-85.04,10c0,54,0,109,0,109"
                />
              </g>
              <g id="curve--2">
                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="298.6401"
                  y1="370.501"
                  x2="298.6401"
                  y2="76.4707"
                >
                  <stop offset={0} style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
                  <stop offset="0.1413" style={{ stopColor: '#FFFFFF', stopOpacity: '0.0283' }} />
                  <stop offset={1} style={{ stopColor: '#FFFFFF', stopOpacity: '0.2' }} />
                </linearGradient>
                <path
                  className="morph-key--2"
                  fill="url(#SVGID_2_)"
                  d="M596.28,370.501c0,0,0-80,0-106.406c-27.28,18.406-55.78,38.906-85.04,21.906
		c-18.382-10.68-63.78-115.49-85.04-115.5c-27.2-0.013-35.2-96-85.04-94c-24.033,0.964-61.136,27.165-85.04,24.499
		c-21.856-2.437-62.521,65.77-85.04,73.001c-38.293,12.297-34.08-17-85.04-21c-29.982-2.354-49.54,41.99-85.04-1.5c0,54,0,219,0,219
		"
                />
              </g>
              <g id="curve--3">
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="298.6401"
                  y1="370.501"
                  x2="298.6401"
                  y2="152.3857"
                >
                  <stop offset={0} style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
                  <stop offset="0.1413" style={{ stopColor: '#FFFFFF', stopOpacity: '0.0283' }} />
                  <stop offset={1} style={{ stopColor: '#FFFFFF', stopOpacity: '0.2' }} />
                </linearGradient>
                <path
                  className="morph-key--3"
                  fill="url(#SVGID_3_)"
                  d="M596.28,370.501c0,0,0-136,0-190.886c-29.28-4.114-55.78-9.224-85.04-26.224
		c-18.382-10.68-63.78,67.192-85.04,67.182c-27.2-0.013-35.2,62.922-85.04,64.922c-24.033,0.965-61.136-70.688-85.04-73.354
		c-21.856-2.437-62.521,14.128-85.04,21.36c-38.293,12.297-34.08,2-85.04-2c-29.982-2.354-49.54,53.49-85.04,10c0,54,0,129,0,129"
                />
              </g>
              <g id="dots--1">
                <rect
                  x="82.154"
                  y="247.505"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 202.9715 12.7965)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="1,268.001 1,257.01 6.496,262.505 	" />
                <rect
                  x="167.194"
                  y="159.233"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 165.4613 -73.1956)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="252.234"
                  y="198.526"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 218.1569 -121.821)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="337.274"
                  y="111.584"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 181.5733 -207.4162)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="422.314"
                  y="11.014"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 135.3673 -297.0048)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="507.354"
                  y="124.744"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 240.6942 -323.8265)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="596.28,221.397 590.784,215.901 596.28,210.406 	" />
              </g>
              <g id="dots--2">
                <rect
                  x="82.154"
                  y="149.505"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 133.672 -15.9102)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="1,158.001 1,147.01 6.496,152.505 	" />
                <rect
                  x="167.194"
                  y="170.233"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 173.2399 -69.9735)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="252.234"
                  y="96.526"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 146.0288 -151.6993)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="337.274"
                  y="72.584"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 153.9961 -218.8391)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="422.314"
                  y="167.014"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 245.676 -251.3135)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="507.354"
                  y="281.744"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 351.71 -277.8422)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="596.28,270.397 590.784,264.901 596.28,259.406 	" />
              </g>
              <g id="dots--3">
                <rect
                  x="82.154"
                  y="227.628"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 188.9158 6.974)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="1,248.001 1,237.01 6.496,242.505 	" />
                <rect
                  x="167.194"
                  y="229.762"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 215.3346 -52.5362)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="252.234"
                  y="208.255"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 225.037 -118.9709)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="337.274"
                  y="281.744"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 301.8947 -157.5775)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="422.314"
                  y="216.637"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 280.7644 -236.7794)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="507.354"
                  y="149.499"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 258.1985 -316.5759)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="596.28,185.055 590.784,179.559 596.28,174.063 	" />
              </g>
              <g id="active-curve">
                <linearGradient
                  id="SVGID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="298.6401"
                  y1="370.501"
                  x2="298.6401"
                  y2="5.5015"
                >
                  <stop offset={0} style={{ stopColor: '#FF9300', stopOpacity: '0.6' }} />
                  <stop offset="0.0926" style={{ stopColor: '#FC8A04', stopOpacity: '0.6185' }} />
                  <stop offset="0.4653" style={{ stopColor: '#F06B12', stopOpacity: '0.6931' }} />
                  <stop offset="0.781" style={{ stopColor: '#E9581B', stopOpacity: '0.7562' }} />
                  <stop offset={1} style={{ stopColor: '#E7511E', stopOpacity: '0.8' }} />
                </linearGradient>
                <path
                  className="morph-key--1"
                  fill="url(#SVGID_4_)"
                  d="M596.28,370.501c0,0,0-114,0-155c-41.28-17-54.28-40-85.04-87
		c-11.642-17.788-63.78-113.99-85.04-114c-27.2-0.013-35.2,98.994-85.04,100.994c-24.033,0.965-61.136,89.312-85.04,86.646
		c-21.856-2.437-62.521-45.872-85.04-38.64c-38.293,12.297-34.08,92-85.04,88c-29.982-2.354-49.54,53.49-85.04,10c0,54,0,109,0,109"
                />
              </g>
              <g id="lines">
                <polyline
                  id="line--3"
                  fill="none"
                  stroke="#FFFFFF"
                  strokeMiterlimit={10}
                  points="1,241.501 86.04,231.514 171.08,233.647 256.12,212.141 
		341.16,285.63 426.2,220.523 511.24,154.167 596.28,179.615 	"
                />
                <polyline
                  id="line--2"
                  fill="none"
                  stroke="#FFFFFF"
                  strokeMiterlimit={10}
                  points="1,152.505 86.04,153.391 171.08,174.119 256.12,100.412 
		341.16,76.47 426.2,170.9 511.24,285.63 596.28,264.901 	"
                />
                <polyline
                  id="line--1"
                  fill="none"
                  stroke="#FFFFFF"
                  strokeMiterlimit={10}
                  points="1.832,262.505 86.04,251.391 171.08,163.119 
		256.12,202.412 341.16,115.47 426.2,14.9 511.24,128.63 596.28,215.901 	"
                />
              </g>
              <g id="dots--1-active">
                <rect
                  x="82.154"
                  y="247.505"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 202.9715 12.7965)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="1,268.001 1,257.01 6.496,262.505 	" />
                <rect
                  x="167.194"
                  y="159.233"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 165.4613 -73.1956)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="252.234"
                  y="198.526"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 218.1569 -121.821)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="337.274"
                  y="111.584"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 181.5733 -207.4162)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="422.314"
                  y="11.014"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 135.3673 -297.0048)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="507.354"
                  y="124.744"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 240.6942 -323.8265)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="596.28,221.397 590.784,215.901 596.28,210.406 	" />
              </g>
              <g id="dots--2-active">
                <rect
                  x="82.154"
                  y="149.505"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 133.672 -15.9102)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="1,158.001 1,147.01 6.496,152.505 	" />
                <rect
                  x="167.194"
                  y="170.233"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 173.2399 -69.9735)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="252.234"
                  y="96.526"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 146.0288 -151.6993)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="337.274"
                  y="72.584"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 153.9961 -218.8391)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="422.314"
                  y="167.014"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 245.676 -251.3135)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="507.354"
                  y="281.744"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 351.71 -277.8422)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="596.28,270.397 590.784,264.901 596.28,259.406 	" />
              </g>
              <g id="dots--3-active">
                <rect
                  x="82.154"
                  y="227.628"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 188.9158 6.974)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="1,248.001 1,237.01 6.496,242.505 	" />
                <rect
                  x="167.194"
                  y="229.762"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 215.3346 -52.5362)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="252.234"
                  y="208.255"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 225.037 -118.9709)"
                  fill="#FFFFFF"
                  width="7.772"
                  height="7.772"
                />
                <rect
                  x="337.274"
                  y="281.744"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 301.8947 -157.5775)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="422.314"
                  y="216.637"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 280.7644 -236.7794)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <rect
                  x="507.354"
                  y="149.499"
                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 258.1985 -316.5759)"
                  fill="#FFFFFF"
                  width="7.773"
                  height="7.772"
                />
                <polygon fill="#FFFFFF" points="596.28,185.055 590.784,179.559 596.28,174.063 	" />
              </g>
            </svg>
            {/* <div className="card__sections">
              <div className="divide" />
              <div className="divide">
                <p>350</p>
              </div>
              <div className="divide">
                <p>300</p>
              </div>
              <div className="divide">
                <p>250</p>
              </div>
              <div className="divide">
                <p>200</p>
              </div>
              <div className="divide">
                <p>150</p>
              </div>
              <div className="divide">
                <p>100</p>
              </div>
            </div> */}
            {/* <div className="card__bg">
              <div className="divide">
                <p>Monday</p>
              </div>
              <div className="divide">
                <p>Tuesday</p>
              </div>
              <div className="divide">
                <p>Wednesday</p>
              </div>
              <div className="divide">
                <p>Thursday</p>
              </div>
              <div className="divide">
                <p>Friday</p>
              </div>
              <div className="divide">
                <p>Saturday</p>
              </div>
              <div className="divide">
                <p>Sunday</p>
              </div>
            </div> */}
          </div>
        </div>
      </FlowChartAll>
    </div>
  )
}

const FlowChartAll = styled.div`
  .layout {
    /* margin: 0;
    max-height: 100vh;
    overflow: visible;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width:100%;
    */
  }

  .card {
    /* background-color: #e1e1e1; */
    /* box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.13); */
    margin: 0 auto;
    max-width: 500px;
    position: relative;
    width: 100%;

    &__bg {
      bottom: 0;
      display: flex;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      .divide {
        width: 5000px;
        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.05);
        }

        p {
          color: #001c28;
          opacity: 0;
          text-align: center;
          transition: all 500ms;
          width: 100%;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);

          p {
            opacity: 1;
          }
        }
      }
    }

    &__sections {
      bottom: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      .divide {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        height: 500px;
        position: relative;

        p {
          color: white;
          padding-left: 1rem;
          position: absolute;
          top: 0;
          width: 100px;
        }
      }
    }
  }

  .graph {
    height: auto;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    #lines {
      display: none;
    }
  }

  #dots--1-active,
  #dots--2-active,
  #dots--3-active {
    display: none;
    opacity: 0;

    rect,
    polygon {
      fill: white;
      transform-origin: center;
    }
  }

  #line--1,
  #line--2,
  #line--3 {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }

  #line--1 {
    animation: dash 9s infinite ease-in-out;
  }

  #line--2 {
    animation: dash 9s -6s infinite ease-in-out;
  }

  #line--3 {
    animation: dash 9s -3s infinite ease-in-out;
  }

  #dots--1,
  #dots--2,
  #dots--3 {
    display: none;
  }

  #dots--1-active {
    animation: dots1 9s infinite ease-in-out;
    transform-origin: center;
    rect {
      animation: dots1-trans 9s infinite ease-in-out;
    }
  }

  #dots--2-active {
    animation: dots1 9s -6s infinite ease-in-out;
    transform-origin: center;
    rect {
      animation: dots1-trans 9s -6s infinite ease-in-out;
    }
  }

  #dots--3-active {
    animation: dots1 9s -3s infinite ease-in-out;
    transform-origin: center;
    rect {
      animation: dots1-trans 9s -3s infinite ease-in-out;
    }
  }
  /*
@keyframes dots1 {
  0% {
	opacity: 1;
  }
  11% {
	opacity: 1;
  }
  20% {
	opacity: 0;
  }
  89% {
	opacity: 0;
  }
  100% {
	opacity: 1;
  }
}

@keyframes dots1-trans {
  0% {
	transform: scale(1) rotate(45deg);
  }
  11% {
	transform: scale(1) rotate(45deg);
  }
  20% {
	transform: scale(0.5) rotate(45deg);
  }
  89% {
	transform: scale(0.5) rotate(45deg);
  }
  100% {
	transform: scale(1) rotate(45deg);
  }
}
*/
  @keyframes dash {
    0% {
      stroke-dashoffset: 0;
    }
    22% {
      stroke-dashoffset: -1000;
    }
    22.01% {
      stroke-dashoffset: 1000;
    }
    88% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`
