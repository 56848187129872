import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const appear = {
  initial: { opacity: 0, y: 100 },
  whileInView: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
  viewport: { once: true },
}

const Talks = ({ talksRef }) => {
  return (
    <Component ref={talksRef}>
      <motion.h2 {...appear}>Talks & Trainings</motion.h2>
      <iframe
        src='https://www.youtube.com/embed/SBHe3dPLIUM?si=em589mXF9S_CI-LC'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen
      ></iframe>
    </Component>
  )
}

export default Talks

const Component = styled.div`
  color: black;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  iframe {
    margin: 40px 0;
    border-radius: 15px;
    box-shadow: 3px 3px 10px rgba(87, 90, 113, 0.4);
    width: min(560px, 90%);
    height: 315px;
  }
`
